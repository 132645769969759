.navigation {
  height: 58px;
}

.link {
  color: var(--white);
  text-decoration-color: var(--black);
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
  text-underline-offset: 0.35rem;
  transition: all 0.2s ease-in-out;
  margin-left: 1rem;
  padding: 0 0.5rem;
  /* text-decoration: none; */
  /* border-color: var(--black); */
}

.dropdown-trigger {
  color: var(--white);
  /* margin-left: 0.8rem; */
  /* padding: inherit 1rem; */
  text-decoration-color: var(--black);
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
  text-underline-offset: 0.35rem;
  transition: all 0.2s ease-in-out;
  margin-left: 1rem;
  padding: 0 0.5rem;
  /* text-decoration: none; */
  /* border-color: var(--black); */
}

.link:hover:not(.link-active) {
  opacity: 0.7;
}

.link-active {
  text-decoration-color: var(--blue);
}

.dropdown {
  width: 208px;
}

.dropdown-link {
  padding: 10px 0.5rem;
}

.dropdown-link:first-of-type {
  padding-top: 15px;
}

.dropdown-link:last-of-type {
  padding-bottom: 6px;
}
