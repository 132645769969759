@media print {
  * {
    overflow: visible !important;
  }

  .print-only {
    display: initial !important;
  }

  .hide-for-print {
    display: none !important;
  }

  .print-pagebreak-after {
    page-break-after: always !important;
  }

  .print-pagebreak-before {
    page-break-before: always !important;
  }

  *[class*='bg-'] {
    background-color: var(--white) !important;
  }

  /* Report 2.0 */
  #report-details section div {
    break-after: page;
    break-inside: avoid;
    break-before: page;
    /* page-break-after: avoid;
    page-break-inside: avoid; */
    padding: 0.5rem 0;
    max-width: 100%;
    margin: 0;
  }

  #report-details .page-header {
    min-height: auto;
    padding: 0.5rem 0;
    break-after: page;
  }

  #report-details .page-header h1 {
    color: var(--black);
    margin: 0;
    padding: 0 2rem;
  }

  #report-details .ph4 {
    padding: 0;
  }

  #report-details .page-header + .pa4 {
    width: 100%;
  }

  #report-details .tree-snapshot {
    max-width: 80%;
    margin: 0 auto;
  }
}
