.nillDot {
  display: none;
}

.legendItem {
  display: flex;
  align-items: center;
}

.separator {
  width: 1px;
  height: 24px;
  margin: 0 2px;
  background-color: var(--black-15);
}

.legendItem {
  padding: 4px 0;
}

.legendItemIcon {
  width: 12px;
  height: 12px;
  background-color: color;
}
