.pagination {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-indicator {
  font-size: 0.813rem;
  appearance: none;
  background-color: transparent;
  padding: 7px 9px;
  border-radius: 4px;
  line-height: 0.813rem;
  font-family: var(--font-stack-mono);
  font-weight: 500;
  color: var(--black-80);
}

.page-overflow-indicator {
  position: 'relative';
  top: 5px;
}

.active-page-indicator {
  padding: 4px 7px;
  margin: 0 2px;
}

.active-page-indicator-colors {
  background-color: var(--blue);
  color: var(--white);
}

.page-control {
  appearance: none;
  background: transparent;
}

.page-control-icon {
  position: relative;
  top: 2px;
  fill: var(--black-50);
}

.page-arrow {
  height: 100%;
  width: 23px;
}

.placeholder {
  minheight: 41px;
}

.page-buttons {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 205px;
}

.page-button {
}
