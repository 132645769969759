.wrapper {
  padding: 5px;
}

.Node {
  border: solid 5px transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 6px;
  width: 250px;
  height: 220px;
}

.Note {
  padding: 0.65rem;
  border: solid 1px var(--black-25);
  border-radius: 0.25rem;
  min-width: 225px;
  height: 200px;
  margin-right: 8px;
}

.noteLabel {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--black-35);
  padding-right: 8px;
}

.nodeHeader {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  text-align: center;
  font-weight: 800;
  color: var(--white);
}

.text {
  padding: 0 8px;
  flex: 3;
  font-size: 1rem;
  line-height: 1.2rem;
}

.noteText {
  padding: 0 8px;
  flex: 3;
  font-size: 0.9rem;
  line-height: 1.1rem;
}

.iconRow {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 8px 4px 2px;
  align-items: center;
  justify-content: flex-end;
}
