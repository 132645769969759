.dropDownBorder .bb {
  border: none !important;
}

.main_menu_wrapper .form-group svg {
  width: 20px;
  height: 20px;
  position: relative;
  left: 4px;
  top: 3px;
}

span.menu_text {
  font-size: 15px;
}

.custom_dropdown {
  position: relative;
  display: inline-block;
}

.custom_dropdown_content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  max-height: 180px;
  width: 337px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 10px 0px 5px;
  z-index: 1;
  margin: 0;
  color: #020001;
}

.custom_dropdown_content.inverse_theme {
  background-color: #b0b0b0;
}

.custom_dropdown_content.active {
  display: block;
}

.custom_dropdown_content li {
  list-style: none;
  cursor: pointer;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

.custom_dropdown_content li:hover {
  background-color: #ccc;
}

.custom_dropdown_content input[type='text'] {
  height: 100%;
}

/* Track */

.custom_dropdown_content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.custom_dropdown_content::-webkit-scrollbar-thumb {
  background: #357edd;
  border-radius: 10px;
}

/* Handle on hover */

.custom_dropdown_content::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.custom_flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
