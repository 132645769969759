.card {
  border: var(--black-20) solid 1px;
  width: 100%;
  border-radius: var(--border-radius);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.cardPadding {
  padding: 21px 24px;
}

.cardHorizontalPadding {
  padding-left: 24px;
  padding-right: 24px;
}

.cardVerticalPadding {
  padding-bottom: 12px;
}

.cardHeader {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.headerTitles {
  display: flex;
  flex-direction: column;
}

.cardContent {
  flex: 3;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.cardStackContent {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

.cardStackRow {
  padding: 24px 0;
}

.currentValueContainer {
  padding: 0 18px;
}

.previousValueContainer {
  padding: 0 12px;
}

.previousValue {
  position: relative;
  min-width: 35px;
  box-sizing: border-box;
}

.figureLabel {
  padding-left: 10px;
}

.figureValue {
  min-width: 35px;
  padding: 0 18px;
}

.figureChangeLabel {
}

.tasksSnapshotCard {
  display: flex;
  /* max-height: 230px; */
  border-radius: var(--border-radius);
  border: solid 1px var(--black-15);
}

.tasksSnapshotCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 22px;
}

.tasksSnapshotContent {
  margin-top: 32px;
  padding-bottom: 24px;
}

.tasksSnapshotFooterLeft {
  padding-left: 24px;
}

.tasksSnapshotFooterRight {
  padding-right: 24px;
}

.viewAllButton {
  background: var(--white);
  border-radius: 2px;
  border: solid 1px var(--black-20);
  color: var(--black-80);
  padding: 8px 14px;
}

.dashedRightBorder {
  border-right: dashed var(--black-15) 1px;
}

.horizontalSeparator {
  /* background-color: var(--black-15); */
  border-top: dashed var(--black-15) 1px;
  height: 1px;
  max-height: 1px;
  align-self: stretch;
  margin-top: auto;
}

.separator {
  background-color: var(--black-15);
  margin-top: 10px;
  margin-bottom: 10px;
  width: 1px;
  max-width: 1px;
  align-self: stretch;
}
