.RCAStatistics {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 1rem;
}

.statColumn {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.statLabel {
  width: 100%;
  text-align: center;
  font-weight: 400;
  line-height: 1rem;
  font-size: 0.9rem;
  letter-spacing: -2%;
  color: var(--black-60);
}

.statValue {
  width: 100%;
  text-align: center;
  max-height: 30px;
  margin: 0;
  font-size: 24px;
  font-weight: 550;
  line-height: 30px;
}

.costWrapper {
  text-align: center;
  cursor: pointer;
}
