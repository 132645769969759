.ReportSectionHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 24px;
  background-color: var(--white);
  border-bottom: var(--black-15) solid 1px;
}

.sticky {
  margin-bottom: 32px;
  position: sticky;
  top: 0;
  z-index: 9;
}

.sectionTitle {
  margin: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -2%;
  font-family: var(--font-stack-serif);
}

.extraContent {
  display: flex;
  width: 65%;
  max-width: 478px;
  margin-left: auto;
}
