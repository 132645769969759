.tableFilters {
  background-color: var(--subtle-white);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top-left-radius: 4px;
  border-top-right-radius: 8px;
  padding: 0 11px;
}

.fixedLayout {
  table-layout: fixed;
}

.headerCell {
  background-color: #f5f6f7;
  border-bottom: 1px solid #ced1d6;
  font-size: 14px;
  line-height: 14px;
  color: #282a2e;
  padding: 0;
}

.isSortable {
  cursor: pointer;
}

.headerCellLabel {
  flex: 2;
  text-align: left;
  padding: 15px 16px;
  font-size: 15px;
}

.headerRow {
  background-color: #f5f6f7;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bodyCell {
  padding: 14px 16px;
  font-size: 0.92rem;
}

.bodyCellTitle {
  margin: 0 0 2px;
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: 0.1px;
}

.bodyCellTitleWrapper {
  width: 100%;
}

.bodyCellTitleWrapper:hover .bodyCellTitle {
  text-decoration: underline;
}

.bodyCellDescription {
  margin: 0;
  font-weight: 300;
  color: var(--black-80);
  font-size: 0.82rem;
  letter-spacing: 0.1px;
}

.headerCellAction {
  min-width: 24px;
  /* margin-top: auto;
  margin-bottom: auto; */
  padding: 0 4px;
}

.border {
  border-right: solid 1px #e3e6eb;
}
