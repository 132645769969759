.sticky {
  position: sticky;
  top: 0;
  max-height: 483px;
  max-width: 270px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.paddedWrapper {
  padding: 2rem 0 1rem;
}

.ReportNavigation {
  width: 100%;
  height: 100%;
  min-width: 220px;
}

.treeEditorLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--black-80);
  padding: 1.5rem 0;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.treeEditorLinkLabel {
  font-weight: 500;
  padding-left: 0.5rem;
}

.NavLink {
  display: block;
  margin-bottom: 16px;
  color: var(--black);
  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
  text-decoration: none;
}

.activeNavLink,
.NavLink:hover {
  text-decoration: underline;
}

.NavHeading {
  display: block;
  margin-bottom: 32px;
  font-size: 15px;
  line-height: 15px;
  font-weight: 550;
}

.standaloneLink {
  margin-bottom: 48px;
}
