.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;
  align-items: stretch;
  background-color: var(--black-60);
}

.visible {
  display: flex;
}

.positionRight {
  margin-left: auto;
}

.positionLeft {
  margin-right: auto;
}

.flyout {
  position: fixed;
  z-index: 9999;
  top: 0;
  height: 100%;
  width: 90%;
  min-width: 300px;
  padding: 24px;
  background-color: var(--white);
  transition: all 300ms ease-in-out;
  overflow-y: hidden;
}
