.menuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 0.92rem;
  padding: 0.7rem 1rem;
}

.disabledMenuItemButton {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.menuItemButton {
  background: none;
  width: 100%;
  text-align: left;
  display: block;
  font-weight: 500;
  font-size: 0.92rem;
  padding: 0.7rem 1rem;
}

.menuItemButton:hover {
  color: var(--white-90);
}

.menuItemButton:first-of-type,
.menuItem:first-of-type {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
