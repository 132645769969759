:root {
  --border-radius: 8px;
  /* lh is used for truncating text; look at .truncate-overflow */
  --lh: 1.4rem;
  --dark-red: rgba(231, 4, 15, 1);
  --dark-red-25: rgba(231, 4, 15, 0.25);
  --dark-red-50: rgba(231, 4, 15, 0.5);
  --dark-red-75: rgba(231, 4, 15, 0.75);
  --white-05: rgba(254, 254, 254, 0.05);
  --white-10: rgba(254, 254, 254, 0.1);
  --white-15: rgba(254, 254, 254, 0.15);
  --white-20: rgba(254, 254, 254, 0.2);
  --white-25: rgba(254, 254, 254, 0.25);
  --white-30: rgba(254, 254, 254, 0.3);
  --white-35: rgba(254, 254, 254, 0.35);
  --white-40: rgba(254, 254, 254, 0.4);
  --white-50: rgba(254, 254, 254, 0.5);
  --white-55: rgba(254, 254, 254, 0.55);
  --white-60: rgba(254, 254, 254, 0.6);
  --white-65: rgba(254, 254, 254, 0.65);
  --white-70: rgba(254, 254, 254, 0.7);
  --white-75: rgba(254, 254, 254, 0.75);
  --white-80: rgba(254, 254, 254, 0.8);
  --white-90: rgba(254, 254, 254, 0.9);

  --white: rgba(254, 254, 254, 1);
  --subtle-white: rgba(251, 251, 251, 1);

  --yellow: #ffde37;
  --blue: rgb(53, 126, 221);
  --blue-25: rgba(53, 126, 221, 0.25);
  --blue-50: rgba(53, 126, 221, 0.5);
  --blue-75: rgba(53, 126, 221, 0.75);
  --gray: #777;
  --dark-gray: #333;
  --light-gray: #eee;
  --black: #000;
  --green: #19a974;
  --gold: #ffb700;
  --orange: #ff8700;
  --bourbon: #e8852e;
  --black-05: rgba(0, 0, 0, 0.05);
  --black-10: rgba(0, 0, 0, 0.1);
  --black-15: rgba(0, 0, 0, 0.15);
  --black-20: rgba(0, 0, 0, 0.2);
  --black-25: rgba(0, 0, 0, 0.25);
  --black-30: rgba(0, 0, 0, 0.3);
  --black-35: rgba(0, 0, 0, 0.35);
  --black-40: rgba(0, 0, 0, 0.4);
  --black-50: rgba(0, 0, 0, 0.5);
  --black-60: rgba(0, 0, 0, 0.6);
  --black-75: rgba(0, 0, 0, 0.75);
  --black-70: rgba(0, 0, 0, 0.7);
  --black-80: rgba(0, 0, 0, 0.8);
  --black-90: rgba(0, 0, 0, 0.9);

  /* branding colors */
  --purple: #5e2ca5;
  --red: #e7040f;
  --magenta: #d5008f;
  --navy-blue: #00449e;
  --light-blue: #357edd;

  --purple-values: 94, 44, 165;
  --red-values: 231, 4, 15;
  --magenta-values: 213, 0, 143;
  --navy-blue-values: 0, 68, 158;
  --light-blue-values: 53, 126, 221;
  --green-values: 25, 169, 116;
  --gold-values: 255, 183, 0;
  --orange-values: 255, 135, 0;

  /* misc */
  --powder-blue: rgb(236, 243, 252); /* added for the callout banner for /#/rcas */

  --font-stack-mono: 'IBM Plex Mono', 'Source Code Pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
  --font-stack-serif: 'IBM Plex Serif', serif;
  --font-stack-sans: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
}

body {
  font-family: var(--font-stack-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--font-stack-mono);
}

.serif {
  font-family: var(--font-stack-serif);
}

.mono {
  font-family: var(--font-stack-mono);
}

html,
body {
  height: 100%;
  font-size: 16px;
}

#root {
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  height: 100%;
  width: 100%;
}

/* input:not(.sa), */
button {
  border: none;
  outline: none;
}

button.unstyled {
  appearance: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.not-allowed {
  cursor: not-allowed;
}

.fill--blue {
  fill: var(--blue);
}

.fill--blue {
  fill: var(--blue);
}

.fill--red {
  fill: var(--dark-red);
}

.fill--white {
  fill: var(--white);
}

.fill--gray {
  fill: var(--gray);
}

.fill--dark-gray {
  fill: var(--dark-gray);
}

.fill--yellow {
  fill: var(--yellow);
}

.fill--gold {
  fill: var(--gold);
}

.fill--orange {
  fill: var(--orange);
}

.fill--black {
  fill: var(--black);
}

.fill--green {
  fill: var(--green);
}

.fill--black-30 {
  fill: var(--black-30);
}

.fill--black-90 {
  fill: var(--black-90);
}

.flip--horizontal {
  transform: rotateX(180deg);
}

.rotate-center {
  -webkit-animation: rotate-center 0.25s linear infinite forwards;
  animation: rotate-center 0.25s linear infinite forwards;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1px, 0, 0);
  }
}

@keyframes spin {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.spin {
  animation: spin 1000ms linear infinite;
}

button {
  cursor: pointer;
}

.no-pointer-events {
  pointer-events: none;
}

.blur {
  /* filter: blur(0.15rem); */
  animation: blur 0.05s ease-out;
  /* -webkit-animation: blur 2s ease-in-out; */
  /* backface-visibility: hidden; */
  /* transform: translateX(3000) scale(1, 1); */
}

.fadeIn {
  animation: fadeIn 1s ease-in-out;
}

.fadeOut {
  animation: fadeOut 1s ease-in-out;
}

.dimIn {
  animation: dimIn 1s ease-in-out;
}

.dimOut {
  animation: dimOut 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes dimIn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dimOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes blur {
  0% {
    filter: blur(1.75rem);
  }
  /* 50% {
    filter: blur(0rem);
  } */
  100% {
    filter: blur(0rem);
  }
}

.transition--short {
  transition-duration: 750ms;
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 250ms;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 250ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 150ms;
}

.fade-exit-done {
  opacity: 0;
}

.trans {
  transition: all 0.2s ease-out;
}

.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate_infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.bg-none {
  background-color: transparent;
}

.bg--bourbon {
  background-color: var(--bourbon);
}

.bg--subtle-white {
  background-color: var(--subtle-white);
}

.bg--light-gray {
  background-color: var(--light-gray);
}

.bg--light-blue {
  background-color: var(--light-blue);
}

.bg--powder-blue {
  background-color: var(--powder-blue);
}

.bg--blue {
  background-color: var(--blue);
}

.bg--blue-50 {
  background-color: var(--blue-50);
}

.bg--blue-75 {
  background-color: var(--blue-75);
}

.bg--black-05 {
  background-color: var(--black-05);
}

.bg--black-25 {
  background-color: var(--black-25);
}

.bg--black-35 {
  background-color: var(--black-35);
}

.bg--black-50 {
  background-color: var(--black-50);
}

.bg--black-75 {
  background-color: var(--black-75);
}

.bg--black-80 {
  background-color: var(--black-80);
}

.bg--black-90 {
  background-color: var(--black-90);
}

.bg--black {
  background-color: var(--black);
}

.bg--yellow {
  background-color: var(--yellow);
}

.bg--gold {
  background-color: var(--gold);
}

.fg--black-05 {
  color: var(--black-05);
  fill: var(--black-05);
}

.fg--black-20 {
  color: var(--black-20);
  fill: var(--black-20);
}

.fg--black-30 {
  color: var(--black-30);
  fill: var(--black-30);
}

.fg--black-50 {
  color: var(--black-50);
  fill: var(--black-50);
}

.fg--black-60 {
  color: var(--black-60);
  fill: var(--black-60);
}

.fg--black-80 {
  color: var(--black-80);
  fill: var(--black-80);
}

.fg--blue {
  color: var(--blue);
  fill: var(--blue);
}

.fg--orange {
  color: var(--orange);
  fill: var(--orange);
}

.fg--green {
  color: var(--green);
  fill: var(--green);
}

.fg--red {
  color: var(--red);
  fill: var(--red);
}

.fg--white {
  color: var(--white);
  fill: var(--white);
}

.fg--white-55 {
  color: var(--white-55);
  fill: var(--white-55);
}

.fg--white-60 {
  color: var(--white-60);
  fill: var(--white-60);
}

.fg--white-65 {
  color: var(--white-65);
  fill: var(--white-65);
}

.fg--white-70 {
  color: var(--white-70);
  fill: var(--white-70);
}

.fg--white-75 {
  color: var(--white-75);
  fill: var(--white-75);
}

.bc--black {
  border-color: var(--black);
}

.bc--white {
  border-color: var(--white);
}

.bc--blue {
  border-color: var(--blue);
}

.bc--green {
  border-color: var(--green);
}

*[class*='fg--black'].fg-hover {
  transition: all 0.1s ease-out;
}

*[class*='fg--black'].fg-hover:hover {
  fill: var(--black);
  color: var(--black);
  transition: all 0.15s ease-in;
}

.br--round {
  border-radius: 50%;
}

.br--pill {
  border-radius: 32px;
}

.br2 {
  border-radius: var(--border-radius);
}

.br1 {
  border-radius: 4px;
}

.opacity--50 {
  opacity: 0.5;
}

.opacity--75 {
  opacity: 0.75;
}

.opacity--25 {
  opacity: 0.25;
}

.bg-grid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23ff8700' fill-opacity='0.4'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.bg-square {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='Artboard-5' fill='%23ff8700' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M6 18h12V6H6v12zM4 4h16v16H4V4z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.bg-hex {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23ff8700' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.bg-cross {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%23ff8700' fill-opacity='0.4'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.bg-grate {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='105' viewBox='0 0 80 105'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='death-star' fill='%23ff8700' fill-opacity='0.4'%3E%3Cpath d='M20 10a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm15 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zM20 75a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zm30-65a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm0 65a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zM35 10a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zM5 45a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zm60 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.bg-shapes {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%23ff8700' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM53 41a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E");
}

.w6 {
  width: 24rem;
}

.select-none {
  user-select: none;
}

.select__indicator:hover,
.select__indicator--is-focused {
  color: var(--black-40);
}
/* .select__option {
  cursor: pointer;
}

.select__option--is-focused {
  background-color: 'rgba(0, 0, 0, 0.2)';
} */
/* .f4.lh-copy.link.grow.ba.ph3.pv2.mr3.dib.white.bg-blue.btn-upload .pv2 {
  display: none !important;

}
.f4.lh-copy.link.grow.ba.ph3.pv2.mr3.dib.white.bg-blue.btn-upload {
padding: .15rem !important;
 min-width: 196px !important;
} */

.facilities-actions .btn,
.equipment-actions .btn {
  min-width: 256px;
}
.facilities-actions .btn-upload,
.equipment-actions .btn-upload {
  min-width: 256px;
  margin-left: 6px;
  margin-right: 6px;
}
.facilities-actions .btn-upload .pv2,
.equipment-actions .btn-upload .pv2 {
  display: none;
}
@media only screen and (max-width: 576px) {
  .facilities-actions,
  .equipment-actions {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .facilities-actions .btn-upload,
  .equipment-actions .btn-upload {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 6px;
    margin-bottom: 6px;
    width: 100%;
  }
  .flUploadWrap {
    width: 100%;
  }
}

.link-button {
  text-decoration: underline;
  color: var(--blue);
  appearance: none;
  background-color: transparent;
}

.icon-button {
  appearance: none;
  background-color: transparent;
}

/*
*
* shared styling utilities
*
*/
.shadow--large {
  box-shadow: 0 16px 40px 0 var(--black-15), 0 30.15px 24.12px 0 var(--black-05),
    0 12.52px 10.02px 0 rgba(0, 0, 0, 0.04), 0 4.53px 3.62px 0 rgba(0, 0, 0, 0.02);
}

.shadow--subtle {
  box-shadow: 5px 10px 20px -9px rgba(0, 0, 0, 0.15);
}

.lightbox-container {
  z-index: 999999 !important;
}

.print-only {
  display: none !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

label {
  cursor: pointer;
}

.truncate-parent {
  line-height: var(--lh);
}

.truncate-overflow {
  --max-lines: 1;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  position: relative;
}

/*
* Type styles
* scale:
* 13 (xxs), 14 (xs), 15 (s), 16 (baseline), 17 (m), 18 (l), 20 (xl), 28 (xxl)
*/
.fs-xxs {
  font-size: 13px;
  line-height: 13px;
}
.fs-xs {
  font-size: 14px;
  line-height: 14px;
}
.fs-s {
  font-size: 15px;
  line-height: 15px;
}
.fs-baseline {
  font-size: 16px;
  line-height: 16px;
}
.fs-m {
  font-size: 17px;
  line-height: 17px;
}
.fs-l {
  font-size: 18px;
  line-height: 18px;
}
.fs-xl {
  font-size: 20px;
  line-height: 20px;
}
.fs-xxl {
  font-size: 28px;
  line-height: 28px;
}

.fw-semibold {
  font-weight: 600;
}

.fw-light {
  font-weight: 300;
}

.fw-medium {
  font-weight: 500;
}

.fw-regular {
  font-weight: 400;
}

.fw-bold {
  font-weight: 700;
}

.ta-center {
  text-align: center;
}

.ta-left {
  text-align: left;
}
.tooltip-zindex-fix {
  z-index: 999999999 !important;
}

.underline--light {
  text-decoration-color: var(--black-60);
}

.hide {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  left: -1000000px;
}

.sticky--top {
  z-index: 9999;
  position: sticky;
  top: 0;
}

.sticky--top-5 {
  top: 65px;
}

.hint {
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
}

.hint em {
  display: inline-block;
  padding: 2px 6px;
  font-style: normal;
  border-radius: calc(var(--border-radius) / 2.25);
  background-color: rgba(var(--gold-values), 0.4);
}

.callout {
}
