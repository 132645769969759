.BackToTop {
  background-color: var(--white);
  padding: 10px 8px;
  appearance: unset;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  position: fixed;
  right: 100px;
  bottom: 32px;
  border-radius: 4px;
  transition: transform 300ms ease-in;
  z-index: 9999;
  transform: translateY(250px);
}

.buttonLabel {
  font-size: 0.9rem;
  line-height: 0.9rem;
  padding-left: 0.5rem;
}

.transitionEnter {
  transform: translateY(250px);
}

.transitionEnterActive {
  transform: translateY(0);
}

.transitionEnterDone {
  transform: translateY(0);
}

.transitionExit {
  transform: translateY(0);
}

.transitionExitActive {
  transform: translateY(250px);
}

.transitionExitDone {
  transform: translateY(250px);
}
