.toolbar {
  width: 100%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 40px 0;
  z-index: 9;
}

.filters {
  margin-left: auto;
  width: 55%;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.heading {
  font-size: 32px;
  line-height: 32px;
  margin: 0;
  padding: 8px 0;
  font-weight: 600;
}

.subheading {
  font-size: 13px;
  line-height: 13px;
  /* margin-top: 8px; */
  margin: 0;
  font-weight: 400;
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.fixedContent {
  width: 75%;
  margin: 0 auto;
}

.fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}
