.popoverContainer {
  position: relative;
}

.popoverContent {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  padding: 0.75rem;
  background-color: var(--white);
  border-radius: var(--border-radius);
}

.confirmationPopover {
  text-align: center;
  display: none;
  padding: 0.7rem 0.7rem;
  position: absolute;
  /* left: -60px;
  bottom: 8px; */
  left: 50%;
  min-width: 120px;
  top: -40px;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  border-radius: 4px;
  box-shadow: 4px 11px 20px -8px rgba(0, 0, 0, 0.2);
}

.wide {
  min-width: 300px;
}

.popoverTrigger {
  appearance: none;
  padding: 0;
  background-color: transparent;
}

.visible {
  display: initial;
}

.confirmationPopoverLabel {
  color: var(--black);
  font-weight: 600;
  letter-spacing: -0.2px;
  margin: 0;
  font-size: 0.82rem;
}

.confirmationPopoverActions {
  padding: 0.5rem 0 0;
}

.confirmationPopoverActionItem {
  /* padding: 0.25rem 0; */
  flex: 1;
  background-color: transparent;
  font-size: 0.9rem;
}

.confirmationPopoverConfirmActionItem {
  padding: 0.12rem 0.4rem;
  border-radius: 4px;
  border-color: var(--red);
  border-width: 1px;
  border-style: solid;
}

.cancel {
  color: var(--gray);
}

.arrow {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.arrowUp {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #fefefe;
}

.arrowDown {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-top: 8px solid #fefefe;
}

.arrowRight {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;

  border-left: 8px solid #fefefe;
}

.arrowLeft {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;

  border-right: 8px solid #fefefe;
}
