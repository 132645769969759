.create-group-popover {
  top: calc(100% + 8px);
  left: -0.5rem;
  width: 340px;
  min-height: 168px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0 4px 7px;
}

.rename-popover {
  overflow-y: auto;
  position: absolute;
  z-index: 9;
  max-height: 300px;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0 4px 7px;
}

.members-popover {
  overflow-y: auto;
  position: relative;
  z-index: 9;
  /* box-sizing: border-box; */
  /* width: 280px; */
  max-height: 300px;
  /* max-width: 480px; */
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0 4px 7px;
}

.members-popover .member-list-item button {
  background-color: transparent;
}

.toggle-button {
  min-width: 108px;
}

.members-popover .member-list-item button:disabled .meta {
  color: var(--black);
}

.members-popover .avatar {
  width: 40px;
}

.members-popover .meta {
  width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.members-popover .full-name,
.members-popover .username {
  width: 100%;
  font-size: 0.85rem;
}

.members-popover .username {
  text-overflow: inherit;
  white-space: inherit;
  overflow-x: inherit;
}

.members-popover.add-member {
  width: 280px;
}

.members-popover ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.members-popover ul li {
  border-bottom: #ccc solid 1px;
}

.members-popover ul li:last-of-type {
  border-bottom: none;
}

.dark-bg {
  background-color: rgba(0, 0, 0, 0.96);
}

.members-popover.dark-bg ul li {
  border-bottom: #1a1a1a solid 1px;
  color: #fefefe;
}

.members-popover.add-member {
  padding-top: 54px;
}

.members-popover.add-member li button {
  color: inherit;
  padding: 0.4rem 0.5rem;
}

.members-popover.add-member ul li {
  padding: 0;
}

.members-popover-header {
  position: fixed;
  background-color: var(--black);
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 0.7rem 0.75rem 0.6rem;
}

/* .members-popover .member {
  padding: 0.5rem 0;
  font-size: 0.5rem;
}

.members-popover .member-meta {
  padding: 1rem 0;
} */

.dismiss-popover {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.popover-header {
  position: relative;
  padding: 19px 19px 21px;
  border-bottom: dashed 1px var(--gray);
}

.popover-title {
  font-size: 18px;
  line-height: 14px;
  font-weight: 700;
  margin: 0 0 8px;
}

.popover-subtitle {
  color: var(--gray);
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin: 0;
}

.popover-fields {
  padding: 19px;
}

.empty-state {
  padding: 1.2rem;
  text-align: center;
}

.group-row td {
  height: 50px;
}

.confirmation-container {
  position: relative;
  z-index: 0;
}

.inline-confirmation {
  min-width: 280px;
  text-align: left;
  display: none;
  padding: 0.65rem 1.5rem;
  position: absolute;
  left: -280px;
  bottom: 30px;
  background-color: #fefefe;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 8px;
}

.inline-confirmation.visible {
  display: initial;
}

.inline-confirmation-label {
  color: var(--black);
  font-weight: 600;
  font-size: 0.9rem;
  text-align: left;
  display: block;
  width: 100%;
}

.inline-confirmation-actions {
  padding: 0.15rem;
}

.inline-confirmation-actions-item {
  padding: 0.25rem 0.4rem;
  background-color: transparent;
}

.inline-confirmation-actions-item.separator {
  color: var(--gray);
  padding: 0.25rem 0.2rem;
}

/* .inline-confirmation-actions-item.confirm {
  color: var(--red);
} */

.inline-confirmation-actions-item.cancel {
  color: var(--gray);
}
