.content {
  position: absolute;
  top: calc(100% + 0.38rem);
  right: 0;
  z-index: 999;
  min-width: 200px;
  background-color: var(--white);
  border-radius: calc(var(--border-radius) / 2);
}

.content-dark {
  background-color: var(--black);
  color: var(--white);
}

.content-dark * {
  color: currentColor;
}

.content-dark .content .contentHeader {
  padding-top: 1.1rem;
  padding-right: 1.1rem;
  padding-bottom: 1.1rem;
  padding-left: 1.1rem;
}

.contentHeaderSlim {
  padding-top: 1.1rem;
  padding-right: 1.1rem;
  padding-bottom: 0.6rem;
  padding-left: 1.1rem;
}

.confirmationMessage {
  padding: 1.1rem;
}

.confirmationWarning {
  font-weight: 600;
  color: var(--red);
}

.confirmationTitle {
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 0.65rem;
}

.confirmationAcceptAction {
  background-color: var(--red);
  color: var(--white);
}

.confirmationCancelAction {
  background-color: transparent;
  color: var(--black-50);
}

.solidContentBorder {
  border: 0.125rem var(--black) solid;
}

.dashedBottomBorder {
  border-bottom: dashed 1px var(--gray);
}

.contentBody {
  padding: 1.1rem;
}

.footerContent {
  padding: 0.5rem 0.5rem;
  background-color: var(--black-05);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.formContent {
  right: 0;
  width: 340px;
  box-shadow: rgb(0 0 0 / 20%) 0 4px 7px;
}

.formContentTitle {
  text-transform: capitalize;
  font-size: 1.1rem;
  line-height: 0.9rem;
  font-weight: 700;
  margin: 0 0 0.5rem;
}

.formContentSubtitle {
  color: var(--black-80);
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  margin: 0;
}
