.reportContainer {
  flex: 4;
  flex-direction: column;
  isolation: isolate;
}

.reportContainer ol,
.reportContainer ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.rootCauseNoteContainer {
  overflow-x: scroll;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding: 16px;
}

.rootCauseTypeIconWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.rootCauseTypeLabel {
  padding-left: 16px;
  font-size: 0.71rem;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  width: 120px;
}

.rootCauseNoteRow:last-of-type td {
  border-top: solid 1px var(--black-15);
}

.rootCauseNote {
  padding: 1rem;
}

.rootCauseNoteTypeLabel {
  /* display: block; */
  color: var(--black-65);
  font-size: 0.7rem;
  padding-bottom: 0.2rem;
}

.timelineEventWrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding: 16px 0;
}

.timelineEvent {
  flex: 1.3 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timelineDate {
  position: relative;
  flex: 2;
  padding: 2px 8px;
  text-align: center;
  border-radius: 4px;
  background-color: var(--blue);
  color: white;
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 0.85rem;
}

.dashedLine {
  border: 0.8px solid var(--black-30);
}

.timelineDate::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  display: block;
  border-right: 6px solid var(--blue);
  border-top: 6px solid var(--blue);
  width: 4px;
  height: 4px;
  /* float: right; */
  transform: translate(50%, -50%) rotate(45deg);
}

.timelineText {
  padding-left: 16px;
  margin: 0;
  flex: 4;
}

.save-report-button:hover {
  background-color: var(--blue);
  color: var(--white);
}

.attachmentPrintImage {
  max-width: 60%;
  display: block;
  margin: 0 auto 1rem;
  break-after: always;
}

.expandControlButton {
  padding: 0.75rem;
  background-color: transparent;
}

.disabledButton {
  opacity: 0.3;
}
