.ReportCard {
  width: 100%;
  max-width: 1107px;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
}

.ReportCard p,
.ReportCard ol,
.ReportCard .row {
  line-height: 24px;
}

.ReportCard .row {
  max-width: 720px;
}

.fullRow,
.ReportCard .fullRow {
  max-width: 100%;
}

/* .ReportCard table {
  max-width: 778px;
} */

.ReportCard.singleColumn .Column {
  width: 100%;
}

.row {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.stripedRow {
  background-color: var(--black-05);
}

.ReportCard.singleColumn .Column {
  width: 100%;
}

.ReportCard .Column {
  max-width: 100%;
}

.CardHeading {
  font-size: 1.9rem;
  line-height: 1.9rem;
  font-weight: 400;
}

.CardSubheading {
  font-weight: 400;
}

.Figure {
  max-width: 369px;
  padding: 56px 32px;
  align-items: center;
  justify-content: center;
}

.figureLabel,
.figureCaption {
  text-align: center;
}

.figureLabel {
  font-size: 80px;
  line-height: 80px;
  letter-spacing: -2%;
  font-weight: 550;
  font-family: var(--font-stack-serif);
}

.figureCaption {
  border-top: solid 1px black;
  padding-top: 0.5rem;
}

.attachment,
.attachmentContainer {
  width: 100%;
}

.copy {
  /* max-width: 700px; */
}

.column {
  display: flex;
  flex-direction: column;
}

.linkIcon {
  color: var(--black-50);
}

/* .tableCell {
  width: 100%;
} */

.Table {
  border-radius: 4px;
  border-collapse: separate;
}

.tableCell {
  padding: 0.8rem;
}

.Table tr:first-of-type .tableCell {
  border-top: none;
}

.Gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
}

.GalleryItemImage {
  /* object-fit: cover; */
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.GalleryItem {
  /* max-width: 240px; */
  max-height: 240px;
  overflow: hidden;
  cursor: pointer;
}

.GalleryItem button {
  background-color: transparent;
  padding: 0;
}

/* .GalleryItem:nth-child(1) {
  grid-column: span 6;
  grid-row: span 2;
}

.GalleryItem:nth-child(2),
.GalleryItem:nth-child(3) {
  grid-column: span 2;
}

.GalleryItem:nth-child(4),
.GalleryItem:nth-child(5) {
  grid-column: span 3;
}*/

.AttachmentFooter {
  width: 100%;
  padding: 1rem;
  /* height: 200px; */
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  background-color: var(--white);
}

.attachmentFooterColumn {
  flex: 1;
}

.attachmentColumnLabel {
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 0.85rem;
}

/* .AttachmentHeader {
  background-color: var(--black-80);
  padding: 1rem;
  width: 100%;
} */
.AttachmentHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachmentHeaderMeta {
  display: flex;
  align-items: stretch;
  background-color: var(--black);
  border-radius: 4px;
  color: var(--white);
  text-align: center;
}

.attachmentLink {
  display: flex;
  width: 100%;
  color: var(--black);
  text-decoration: none;
  overflow: visible;
  font-size: 0.9rem;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--black-05);
}

.attachmentFilename {
  flex: 3;
}

.attachmentType {
  flex: 2;
}

.attachmentFilename,
.attachmentType {
  padding: 0.75rem 1rem;
}
