.input {
  padding-right: 0.7rem;
  width: 100%;
  cursor: pointer;

  font-size: 0.875rem;
  line-height: 0.875rem;

  border-color: var(--black-15);
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
}

.value-container {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  height: 100%;
  padding-left: 8px;
  /* flex-grow: 1; */
}

.methodology-select {
}

.select {
  /* max-width: 300px; */
}

.select-option,
.select-option:hover,
.select-option:focus,
.select-option:active {
  cursor: pointer;
}

.select-option--light:hover,
.select-option--light:focus,
.select-option--light:active {
  background-color: rgb(244, 244, 244);
  border-bottom-color: rgba(230, 230, 230);
}

.select-option {
  padding: 8px 14px;
}

.select-option--light {
  color: var(--black);
  border-bottom: 1px solid #eee;
}

.select-option--dark {
  border-bottom: 1px solid #323232;
}

.select-option--dark:hover,
.select-option--dark:focus,
.select-option--dark:active {
  background-color: rgba(50, 50, 50, 0.45);
  border-bottom-color: rgba(30, 30, 30, 1);
}

.select-option--dark .labels {
  color: rgb(244, 244, 244);
}

.select-option--dark {
  color: var(--white);
  background-color: #202020;
}

.select-option--dark:last-of-type,
.select-option--light:last-of-type {
  border-bottom: none;
}

.select-option-badge {
  background-color: #eee;
  border-radius: 4px;
  display: flex;
  margin-left: auto;
  font-size: 12px;
  justify-self: center;
  align-self: center;
  padding: 4px 6px;
}

.select-option-badge--dark {
  /* background-color: rgba(0, 0, 0, 0.9); */
  background-color: #363437;
  color: var(--white);
}

/* .dark .select-option-badge {
  background-color: rgba(244, 244, 244, 0.1);
} */

/* .dark .select__indicator,
.dark .select__value-container,
.dark .select__value-container .select__single-value {
  fill: rgba(244, 244, 244, 0.5) !important;
  color: rgba(244, 244, 244, 0.5) !important;
} */

/* .dark .select__indicator:hover,
.dark .select__indicators:hover,
.dark .select__indicator.select__dropdown-indicator:hover,
.dark .select__indicator.select__clear-indicator:hover {
  fill: rgba(50, 244, 244, 0.9) !important;
  color: rgba(50, 244, 244, 0.9) !important;
} */

.methodology-select-option {
  border-bottom-style: solid;
  border-bottom-color: var(--black-10);
  border-bottom-width: 1px;
}

.methodology-select-option:last-of-type {
  border-bottom: none;
}

.methodology-select-option-label {
  line-height: 20px;
}

.date-period-select {
}

.icon-wrapper {
  display: flex;
  width: 2rem;
  padding: 0 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search {
  height: 40px;
  background-color: var(--white);
  border-color: var(--black-15);
  border-radius: 4px;
  color: #fbfbfb;
}

.search-input {
  border-color: transparent;
  border-width: 0px;
}

.search:focus {
  border-color: var(--blue);
}

.deep-select {
  font-size: 14px;
  line-height: '120%';
  color: #fefefe;
}

.deep-select-light {
  font-size: 14px;
  line-height: '120%';
  color: rgba(0,0,0,0.8);
}

/* .deep-select:hover,
.deep-select:focus {
  border-color: red;
} */

.deep-select-option {
  background-color: #202020;
  padding: 12px;
}

.deep-select-option-light {
  background-color: white;
  padding: 12px;
}

.deep-select-placeholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* 
  1. we have to include this extra selector because react-select's "Input" component isn't actually the input. It's a div. The actual input is a child of that div. 
  2. !important is required for the color inheriting because react-select's default styling does not go away when provided with overrides (via styles, classNames, and component replacement)
*/
.deep-select-input,
.deep-select-input input {
  color: inherit !important;
}

.option-bottom-border {
  border-bottom: solid 1px #323232;
}

.option-bottom-border-light {
  border-bottom: solid 1px #CBCBCB;
}

.deep-select-option-toggle {
  display: none;
}

.deep-select-control:focus {
}

.deep-select-option:hover,
.deep-select-option:focus {
  background-color: #171717;
}

.deep-select-option-light:hover,
.deep-select-option-light:focus {
  background-color: #F4F4F4;
}

.deep-select-option:hover .deep-select-option-toggle {
  display: block;
}

.deep-select-deep-label {
  color: #d9d9d9;
  opacity: 0.6;
  margin-bottom: 8px;
}

.deep-select-deep-label-light {
  font-size: 12px;
  color: rgba(128,128,128,1.0);
  margin-bottom: 8px;
}

.deep-select-name-label {
  margin-bottom: 4px;
  max-width: 90%;
}

.deep-select-deep-label,
.deep-select-path-label {
  font-size: 12px;
  text-transform: lowercase;
}

.deep-select-path-label-light {
  font-size: 12px;
  text-transform: lowercase;
  color: rgba(128, 128, 128, 1.0);
}