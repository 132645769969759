.FloatingNav {
  composes: shadow--large from '../../index.css';
  max-width: 266px;
  background-color: var(--black);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.send {
  position: relative;
  left: -2px;
  top: 1px;
}

.button {
  max-width: 25%;
  background-color: transparent;
  border-right: var(--white-25) solid 1px;
  padding: 0.75rem 1rem 0.65rem;
}

.button:last-of-type {
  border-right: none;
}

.button:hover svg {
  fill: var(--gold);
  transition: fill ease-in-out 200ms;
}

.button svg {
  fill: var(--white);
  transition: fill ease-in-out 80ms;
}

.tooltip {
  padding: 0.5rem 0.65rem !important;
}

.tooltipLabel {
  font-size: 0.85rem;
}
